<template>
    <span :style="$store.state.data.length > 0 ? '':'min-height: 58px; display: block;'">
        <div v-bind:key="item.id" @mouseover="mouseOver(item.guid)" @mouseout="mouseOut(item.guid)"
             class="kris-regalii-box" v-for="item in $store.state.data">
            <a :href="item.link" target="_blank" class="kris-regalii-box"
               :style="{backgroundImage: 'url('+item.image+')'}"></a>
            <div class="hint" :style="{marginTop:'50px', marginLeft:'-55px'}" v-show="showHint(item.guid)">
                <div class="hint_top"></div>
                <div class="hint_text">
                    {{ item.description }}
                </div>
            </div>
        </div>
    </span>

</template>

<script>
    import Vue from 'vue'

    export default {
        name: "Pennants",


        data() {
            return {
                hint: {}
            };
        },


        created() {
            this.$store.commit("setEoId", document.body.getAttribute("data-eo-id"))
            var $this = this
            this.$store.dispatch("getPennants").then(() => {
                if ($this.$store.state.data.length > 0) {
                    for (var i = 0; i < $this.$store.state.data.length; i++) {
                        Vue.set($this.hint, $this.$store.state.data[i].guid, false);
                    }
                }
            })
        },


        methods: {
            mouseOver: function (guid) {
                Vue.set(this.hint, guid, true);
            },


            mouseOut: function (guid) {
                Vue.set(this.hint, guid, false);
            },


            showHint: function (guid) {
                return this.hint[guid];
            }
        },

    }
</script>

<style lang="less">
</style>
