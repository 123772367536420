import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      api: "https://api-sites.mskobr.ru",
      pennants: "/pennants/info",
      eoId: false,
      data: []
  },

  mutations: {
      setEoId(state, value){
          state.eoId = value
      },
  },

  actions: {
      getPennants({state}){
          const path = "https://info-sites.mskobr.ru/api/pennants.json?eoId=" + state.eoId
          return Axios({
              method: "GET",
              url: path
          }).then((res)=>{
              if ( res.status == 200 ) {
                  state.data = res.data.items
              }
          })
      },
      getPennantsOld(){
          return new Promise((resolve, reject)=>{
              var path = this.state.api + this.state.pennants + "/" + this.state.eoId + ".json"
              Axios({
                  method: "GET",
                  url: path,
              }).then((res)=>{
                  if( res.status == 200 ){
                      this.state.data = res.data.items
                      resolve()
                  }else {
                      reject(res)
                  }
              }).catch((err)=>{
                  reject(err)
              })
          });

      }
  }
})
